import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function SrCloudArchitect() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>Sr. Cloud Architect</h1>

      <div className="jobDescription">
        <p>
          This is a full-time opportunity to support our federal customer in Washington D.C.
          Fully Remote – Eastern Standard Time, US citizen is required. Green card holders accepted.
          We are looking for a motivated Cloud Architect to join the team to support operations, maintenance,
          and enhancement of the Integrated Research and Analytics Platform (IRAP) Portal.
        </p>
        <p>
          The IRAP is a cloud-based Data and Analytics platform that provides seamless data management
          and analytics experience that allows Board researchers the ability to search data catalogs, access
          data, and collaborate with others.
        </p>

        <div className="section">
          <h2>Qualifications</h2>
          <ul>
            <li>10 years’ experience designing and building performant, cost-optimized systems in AWS.</li>
            <li>10 years' experience designing data platforms, data lakes, data meshes, data fabrics, data warehouses, or large data management systems.</li>
            <li>8 years' experience using Agile software development methodologies.</li>
            <li>8 years’ experience designing and implementing data pipelines/ETL/ELT technologies.</li>
            <li>8 years’ experience with designing databases, including creating entity relationship diagrams for logical and physical database models and associated data dictionaries.</li>
            <li>Knowledge of data technologies such as SQL, Apache Hadoop, Spark, Airflow, etc.</li>
            <li>Experience presenting technical solutions to Architecture/Software review board.</li>
            <li>Excellent communication and interpersonal skills and ability to effectively interact with stakeholders of varying levels of technology acumen.</li>
            <li>Knowledge of NIST & FISMA security review and ATO process.</li>
            <li>Experience acquiring in-depth understanding of large complex software systems to isolate defects, reproduce defects, assess risk, and understand varied customer deployments.</li>
            <li>Hand-on experience building cloud infrastructure on AWS.</li>
            <li>Hands-on experience implementing data pipelines in AWS.</li>
            <li>Bachelor's degree or higher in Computer Science, Business Administration Science, Mathematics, or Engineering.</li>
            <li>Automated software delivery in a CI/CD environment using Jenkins, Gitlab CI, or similar.</li>
            <li>Professional Cloud Architect Certification.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Capabilities</h2>
          <ul>
            <li>Understanding of and ability to evaluate new technologies for fit in current infrastructure architecture.</li>
            <li>Understanding of cloud-based architectures, web servers, caching, application servers, load balancers, and storage.</li>
            <li>Familiarity with loose coupling, stateless systems, and best practices for designing cloud-ready applications.</li>
            <li>Understanding of cloud federation technologies such as SAML, OAuth, and OpenID Connect and how to apply these technologies to enterprise and public-facing applications.</li>
            <li>Awareness of cloud information security risks and best practices, especially in highly secure operating environments.</li>
            <li>Experience transitioning legacy systems to cloud-ready architecture.</li>
            <li>Experience with route tables, access control lists, firewalls, NAT, HTTP, DNS, IP, and OSI Network.</li>
            <li>Familiarity with government cloud deployment regulations/compliance policies such as FedRAMP, FISMA, etc.</li>
            <li>Utilizes emerging technologies and leads cloud teams to implement cloud design principles and develop cloud automation, interfaces, and architecture to support organization initiatives.</li>
          </ul>
        </div>

        <div className="section">
          <h2>You Shall Deliver</h2>
          <ul>
            <li>Generate new ideas for changes and improvements to IRAP and associated technologies.</li>
            <li>Drive opportunities for reuse, best practice sharing, and consumption acceleration.</li>
            <li>Proactively identify gaps through delivery and communicate those gaps to others.</li>
            <li>Design data architecture, data engineering, and analytics design patterns to enhance the capabilities of the IRAP platform; create conceptual, logical, and physical designs and associated design documents and diagrams for IRAP.</li>
            <li>Perform evaluation of software tooling options by defining comprehensive evaluation criteria and rating tooling options against the defined evaluation criteria.</li>
            <li>Conduct Proof of Concepts (POC) for technical solutions and software tools.</li>
            <li>Represent IRAP in the Architecture Review Board and Software Review Board to get approval for designs and software tools, including Commercial Off-The-Shelf (COTS) tools.</li>
            <li>Assist in defining security controls implementation guidance for IRAP in accordance with FISMA and NIST 800-53.</li>
            <li>Develop and expand existing impactful relationships with partner architects, technical specialists, technical decision-makers, and business stakeholders.</li>
            <li>Receive and synthesize data about customer business and technical requirements, address them with technical architectures, and demonstrate and prove those solutions' capability and value through vibrant technology design.</li>
            <li>Guide other team members on Cloud technology, data architecture, data engineering, and DevSecOps.</li>
            <li>Enhance capabilities of IRAP in collaboration with Board Data Architects, Cloud Administrators, and other stakeholders.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SrCloudArchitect;
