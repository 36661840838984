import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function SharePointDeveloper() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>SharePoint Developer</h1>

      <div className="jobDescription">
        <p>
          We are seeking a SharePoint Developer to work alongside a team supporting this redevelopment work supporting the larger migration effort. Customer is in the midst of migrating 2019 SharePoint On-Premise sites to SharePoint online. Customer’s IT will automate and perform a large majority of the migration work, but there are some legacy sites with complex workflows (some using soon-to-be deprecated technology like InfoPath) that will need to be redeveloped for our customers.
        </p>

        <div className="section">
          <h2>PERSONAL QUALIFICATIONS:</h2>
          <ul>
            <li>At least five (5) years of experience on most recent SharePoint version as administrator or developer.</li>
            <li>At least five (5) years of experience and knowledge based on but not limited to one or more of the capabilities languages.</li>
          </ul>
        </div>

        <div className="section">
          <h2>CAPABILITIES:</h2>
          <ul>
            <li>Ability to gather requirements, design, and develop solutions that fill those requirements.</li>
            <li>Ability to work independently and in a team environment working directly with customers, training staff developers, explaining solutions, and alternatives to customers, staff, and sponsors.</li>
            <li>Preferred: Experience with SharePoint integration with the MS O365 environment to include use of SharePoint List, MS Forms, and PowerApps.</li>
            <li>Preferred: Working knowledge of and experience with .NET/SQL Server/JavaScript/CSS.</li>
            <li>Experience coding in the following language(s): Adobe Flash, HTML 5, CSS 3, Mobile Framework (e.g., IOS, Android), Java Script / jQuery Plug-ins Library, Content Management System (CMS), Master Pages Customizations, Search / Enterprise, Search / Fast, Social Computing.</li>
          </ul>
        </div>

        <div className="section">
          <h2>EXPECTATIONS:</h2>
          <ul>
            <li>Follow Customer’s IT standards and industry best practice, as directed.</li>
            <li>Serve as a developer supporting the efforts to migration from SharePoint on-premise to SharePoint Online.</li>
            <li>Create and update application documentation and/or instructions, where applicable.</li>
            <li>Provide training to the team and end-users, where applicable.</li>
            <li>May also be assigned to other ad-hoc tasks relevant to this job as applicable to work of this department and as assigned by the manager.</li>
          </ul>
        </div>

        <div className="section">
          <h2>PLACE OF PERFORMANCE:</h2>
          <p>On-site at Client location, Washington, DC. At least 6 days/month on-site required.</p>
        </div>

        <div className="section">
          <h2>CITIZEN STATUS:</h2>
          <p>NOTE: U.S. Citizenship required.</p>
        </div>
      </div>
      <h1>
        Qualified? Email{' '}
        <a href="mailto:hr@bxtpartners.com">hr@bxtpartners.com.</a>
      </h1>
    </div>
  );
}

export default SharePointDeveloper;
