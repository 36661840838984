import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function SrCloudSecuritySpecialist() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>Sr. Cloud Security Specialist</h1>

      <div className="jobDescription">
        <p>
        We are seeking an experienced individual to provide services in support of the continuous
        monitoring of FedRAMP authorized cloud solutions. We are looking for an experienced
        information security specialist to work directly with cross-division subject matter experts
        (SMEs) and provide insight, advice, support, and recommendations to ensure the success of the
        continuous monitoring process, with a targeted focus in FedRAMP, FISMA, Privacy Act, and
        OMB requirements. The selected individual shall have experience successfully reviewing
        FedRAMP continuous monitoring packages and advising on secure cloud control
        implementation. The successful candidate shall be adept at working collaboratively in a
        consensus-based environment while serving as an individual contributor who develops
        information security related work products.
        </p>

        <div className="section">
          <h2>Qualifications and Capabilities</h2>
          <ul>
            <li>Bachelor’s degree or higher in information security, or a related field, or equivalent experience.</li>
            <li>At least one of the following certifications preferred: CompTIA Security+, Certificate of Cloud Security Knowledge (CCSK).</li>
            <li>At least one advanced cybersecurity certification such as: CISSP, CCSP, CRISC, or other relevant security certifications; multiple are preferred.</li>
            <li>Minimum of 6 years’ experience on any Cloud Platform (AWS, Azure, Google, others).</li>
            <li>At least seven (7) years of information security experience, including cloud security and continuous monitoring activities.</li>
            <li>Extensive NIST experience: NIST SP 800-30 rev 1, 800-37 rev 1 or 2, 800-53 rev 5, 800-60 Vol 1 rev 1 & 2 rev 1, and 800-171 rev 3.</li>
            <li>Experience with implementing systems in a FedRAMP, FISMA, and SOX compliant environment.</li>
            <li>Proven ability to forge consensus and work collaboratively, without positional authority, to influence stakeholder groups in different hierarchical structures.</li>
            <li>Strong project execution and project management capabilities.</li>
            <li>Experience with FedRAMP reporting requirements, including risk assessments, Plan of Action and Milestones (POA&M), and remediation plans.</li>
            <li>Design, develop, test, operate, and sustain security computing architectures, including cloud, data centers, and overall infrastructure optimization activities.</li>
            <li>Synchronize the technical strategy for multiple agile development projects.</li>
            <li>Delivery in an Agile, Lean, SAFe-based environment utilizing DevSecOps, ARTs, CI/CD, and related processes, methodologies, and concepts.</li>
            <li>Serve as a cloud security expert, integrating sound practices from Identity and Access Management, monitoring, platform standards, network segmentation and interconnection, encryption, and more into the cloud platforms.</li>
            <li>Work with Enterprise Architects, other functional area architects, and security specialists to ensure adequate security solutions are in place throughout cloud IT systems and platforms.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Preferred Knowledge and Experience</h2>
          <ul>
            <li>Experience with government compliance, including OMB requirements, FISMA, FedRAMP, RMF, and CSF.</li>
            <li>Experience with cloud environments, architectures, technologies, and services.</li>
            <li>FedRAMP experience and certification.</li>
            <li>Advanced knowledge and experience with project management methodology, information security compliance, and implementation of security architectures and related standards.</li>
            <li>Knowledge of the laws and regulations governing information security and compliance.</li>
            <li>Excellent communication skills (verbal and written), with the ability to adjust to changing priorities and customer needs.</li>
            <li>Strong interpersonal and organizational agility skills, capable of dealing effectively with all levels of management and staff.</li>
            <li>Detail-oriented and committed to excellent customer service.</li>
          </ul>
        </div>

        <div className="section">
          <h2>The Contractor shall deliver, but not limited to, the following</h2>
          <ul>
            <li>Review current continuous monitoring program and provide recommendations for improvement.</li>
            <li>Conduct monthly FedRAMP continuous monitoring package analysis, including reviewing deviation requests and POA&Ms, and document a summary for the client.</li>
            <li>Advise clients on FISMA/FedRAMP compliance activities while staying current with relevant legislation and requirements from the National Institute of Standards and Technology (NIST) and Office of Management and Budget (OMB).</li>
            <li>Conduct security risk assessments for third-party applications and service providers.</li>
            <li>Ensure organizational structure recommendations integrate cohesively into the overall DFM and Board strategic direction, aligning with other high-priority work across the division.</li>
            <li>Identify and plan for long-term financial considerations due to cloud migration and business transformation.</li>
            <li>Review and advise on post-implementation decommissioning schemes for legacy applications, as well as the migration and maintenance of historic data.</li>
            <li>Review additional processes and procedures and make recommendations for improvement to the client.</li>
            <li>Provide ad-hoc support services, including advisory consulting services for Board leaders, facilitating strategic meetings or working sessions, and reviewing and opining on FR system documentation.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Place of Performance</h2>
          <p>Remote with the organization's discretion to request occasional on-site presence at Client locations in Washington, DC.</p>
        </div>

        <div className="section">
          <h2>Citizenship Status</h2>
          <p>US Citizenship is required.</p>
        </div>

        <div className="section">
          <h2>Period of Performance</h2>
          <p>Till Dec 31, 2024, with the possibility of extension through June 2025.</p>
        </div>
      </div>
    </div>
  );
}

export default SrCloudSecuritySpecialist;