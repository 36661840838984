import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function CloudArc() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>Cloud Architect</h1>

      <div className="jobDescription">
        <p>
          We are looking for a motivated Intermediate Cloud Architect to join the team to support operations, maintenance, and enhancement of the Integrated Research and Analytics Platform (IRAP) Portal, which is a custom-built web application built using .Net and React on AWS GovCloud infrastructure. This platform provides seamless data management and analytics experience that allows researchers the ability to search data catalogs, access data, and collaborate with others.
        </p>

        <div className="section">
          <h2>Qualifications</h2>
          <ul>
            <li>Bachelor's degree or higher in Computer Science, Business Administration Science, Mathematics, or Engineering.</li>
            <li>Minimum of 5 years of experience with integration, systems analysis, or programming experience within Cloud environments.</li>
            <li>Minimum of 5 years of experience developing systems requirements and design specifications.</li>
            <li>5 years' experience designing, building, and maintaining Cloud infrastructure in AWS.</li>
            <li>7 years' experience performing system administration activities including patching, upgrading, and maintenance Windows and Linux servers.</li>
            <li>3 years' experience building AWS Infrastructure using Terraform.</li>
            <li>5 years' experience using Agile software development methodologies.</li>
            <li>3 years' experience building CI/CD pipelines, preferably using Azure DevOps or Gitlab.</li>
            <li>Excellent communication and interpersonal skills and ability to effectively interact with stakeholders of varying levels of technology acumen.</li>
            <li>Knowledge of FISMA security review and ATO process.</li>
            <li>Experience acquiring in-depth understanding of large complex software systems to isolate defects, reproduce defects, assess risk, and understand varied customer deployments.</li>
            <li>At least seven years' demonstrated experience in developing software according to software development lifecycles (SDLCs), including DevOps, Agile, Lean, Iterative, or Waterfall.</li>
            <li>Proficient in at least one high-level programming languages such as Python, Ruby, or Go.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Capabilities</h2>
          <p>Analyzes functional business applications and design specifications for functional activities.</p>
          <ul>
            <li>Understanding of and ability to evaluate new technologies for fit in current infrastructure architecture.</li>
            <li>Understanding of cloud-based architectures, web servers, caching, application servers, load balancers, and storage.</li>
            <li>Familiarity with loose coupling, stateless systems, and best practices for designing cloud-ready applications.</li>
            <li>Understanding of cloud federation technologies such as SAML, OAuth, and OpenID Connect and how to apply these technologies to enterprise and public-facing applications.</li>
            <li>Awareness of cloud information security risks and best practices, especially in highly secure operating environment.</li>
            <li>Experience transitioning legacy systems to cloud-ready architecture.</li>
            <li>Experience with route tables, access control lists, firewalls, NAT, HTTP, DNS, IP, and OSI Network.</li>
            <li>Familiarity with government cloud deployment regulations/compliance policies such as FedRAMP, FISMA, etc.</li>
          </ul>
        </div>

        <div className="section">
          <h2>You shall deliver, but not limited to, the following:</h2>
          <ul>
            <li>Maintain IRAP Platform by identifying, analyzing, and resolving any emergent vulnerabilities in a timely fashion.</li>
            <li>Perform any required version upgrades to the tool stack used by the platform.</li>
            <li>Perform required monthly maintenance activities such as upgrading servers to use the latest version of Gold AMI, performing patches, testing the platform after maintenance etc.</li>
            <li>Perform security monitoring to maintain ATO of the IRAP platform.</li>
            <li>Perform activities required to update the ATO of IRAP to host restricted datasets.</li>
            <li>Enhance capabilities of IRAP in collaboration with Board Data Architects, Cloud Administrators, and other stakeholders.</li>
            <li>Ensure IRAP platform is in sync with the IaC Terraform code in the code repository.</li>
            <li>Monitor cloud resource consumption and alert leadership if the resource usage exceeds set limits.</li>
            <li>Identify opportunities for cloud cost savings. Ensure optimized use of AWS resources within IRAP by rightsizing, optimizing, and reengineering the infrastructure.</li>
            <li>Identify and manage external dependencies for platform enhancement efforts to ensure timely delivery of enhancements.</li>
            <li>Perform development of cloud infrastructure using terraform and deploy infrastructure to production after successful completion of comprehensive testing.</li>
            <li>Perform IRAP platform user administration activities including but not limited to onboarding and provisioning new users, addressing, and resolving user enquiries, concerns, and issues.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Place of Performance</h2>
          <p>Remote - Eastern Standard Time</p>
        </div>

        <div className="section">
          <h2>Citizenship Status</h2>
          <p>US citizen is required. Green card holders accepted.</p>
        </div>
      </div>
      <h1>
        Qualified? Email{' '}
        <a href="mailto:hr@bxtpartners.com">hr@bxtpartners.com.</a>
      </h1>
    </div>
  );
}

export default CloudArc;
