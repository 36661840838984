import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function SrNetworkEngineer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>Sr. Network Engineer</h1>

      <div className="jobDescription">
        <p>
          We are seeking a senior network engineer with a strong background in data center routing and switching to assist with projects for our customer in Washington D.C.
        </p>

        <div className="section">
          <h2>Qualifications</h2>
          <ul>
            <li>Minimum of 10 years’ experience in supporting enterprise-grade routers and switches in a large data center environment.</li>
            <li>Demonstrating expertise in supporting and configuring the following technologies: BGP, OSPF, STP in NXOS and IOSXE platforms.</li>
            <li>Experience designing, planning, testing, implementing, supporting and troubleshooting large data center networks.</li>
            <li>Experience with Cisco Nexus switches (9K, 7K, etc) and Cisco Catalyst switches and routers.</li>
            <li>XVLAN, Wireless and AWS networking is a plus.</li>
            <li>Bachelor’s degree in Computer Science, Systems Engineering, Information Systems, Engineering, Telecommunications, or related field.</li>
            <li>Must demonstrate knowledge of the most current Information Technology Infrastructure Library (ITIL) standards.</li>
            <li>One of the following certifications preferred: ITIL, CCNA, CCNP, Network+, etc.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Capabilities</h2>
          <ul>
            <li>Thorough knowledge of local and wide area networking technologies, protocols, and equipment as well as network security and business continuity.</li>
            <li>Experience with cloud environments (AWS, Azure, etc.).</li>
            <li>Apply DevSecOps principles, agile software engineering best practices, and tools to IT infrastructure and operations.</li>
            <li>Support major network architecture changes, like new devices deployment and placement, network architecture redesign, hardware configuration changes, wireless deployments, and implementation/modification of test/development/medical device enclaves.</li>
          </ul>
        </div>

        <div className="section">
          <h2>You Shall Deliver</h2>
          <ul>
            <li>Assist the networking team with daily operations and ongoing projects, including troubleshooting routing and switching issues, developing new designs, managing maintenance windows, and handling any other tasks as required.</li>
            <li>Extensive expertise in BGP and OSPF, traffic engineering, route optimization, and failover scenarios.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Place of Performance</h2>
          <p>Hybrid, Washington, DC</p>
          <p>NOTE: This position requires a mixture of onsite and remote work. The Contractor will be onsite for 1 day per week.</p>
        </div>

        <div className="section">
          <h2>Citizenship Status</h2>
          <p>US citizen is required.</p>
        </div>
      </div>
      <h1>
        Qualified? Email{' '}
        <a href="mailto:hr@bxtpartners.com">hr@bxtpartners.com.</a>
      </h1>
    </div>
  );
}

export default SrNetworkEngineer;
