import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function BusinessProcessEngineer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>Business Process Engineer</h1>

      <div className="jobDescription">
        <p>
          We are seeking a Business Process Engineer to support the Technology Services -
          Planning, Governance & Innovation IT Business Management Team for our federal
          clients located in Washington, DC.
        </p>

        <div className="section">
          <h2>PERSONAL QUALIFICATIONS:</h2>
          <ul>
            <li>Bachelor’s Degree and at least six (6) years of related experience, or Master’s Degree and at least four (4) years of related engineering experience, or 10 years of Analyst experience.</li>
            <li>At least five (5) years of experience in leading implementation of the ITIL best practices at the operational level, and business process reengineering experience for incident, problem, change, release, and configuration management.</li>
            <li>ITIL 4 Foundations Certification preferred.</li>
            <li>At least one (1) year of experience with Microsoft Office.</li>
            <li>At least three (3) years of leading requirements discovery and management.</li>
            <li>At least four (4) years of lifecycle documentation.</li>
          </ul>
        </div>

        <div className="section">
          <h2>CAPABILITIES:</h2>
          <ul>
            <li>Experience with identifying all the processes in an organization and prioritizing them in order of redesign urgency.</li>
            <li>Diagnose and improve business processes and increase efficiency.</li>
            <li>Understand the concept of continuous integration / continuous delivery and be familiar with Kanban boards and Agile methodologies.</li>
            <li>Demonstrated ability to use ITIL concepts and best practices to reengineer existing ITSM processes. ITIL Certification preferred.</li>
            <li>Expertise with analyzing past trends, monitoring workflow, making predictions, developing business process models, recommending enhancements, and assisting the team in making business process decisions.</li>
            <li>Applies process improvement and reengineering methodologies and principles to conduct process modernization projects.</li>
            <li>Providing support for key stakeholders by evaluating changes in business processes and product offerings that significantly impact the end-to-end process for ITSM.</li>
            <li>Activity and data modeling, developing modern business methods, identifying best practices, and creating and assessing performance measurements.</li>
            <li>Provides group facilitation, interviewing, training, and provides additional forms of knowledge transfer.</li>
            <li>Key coordinator between multiple project teams to ensure enterprise-wide integration of reengineering efforts.</li>
            <li>Provides daily supervision and direction to business process reengineering process.</li>
            <li>Experience creating lifecycle documents: Use Cases, Detailed Functional Requirements, Detailed Design Specifications, Business Process Models and Test Cases.</li>
            <li>Experience leading requirements discovery working sessions with an audience of customer and systems Subject Matter Experts (SMEs) and translating the results of requirements discovery sessions into Requirements / Design documents and Use Cases.</li>
            <li>Knowledge and experience with vendor management, resource capacity management, documentation management and IT business / financial practices.</li>
            <li>Experience using Visio, Word, Excel, and PowerPoint, ServiceNow, Azure DevOps (ADO).</li>
            <li>Strong influence-management, negotiation, leadership, and assertiveness skills.</li>
            <li>Ability to work independently as well as in a team environment.</li>
            <li>Shows respect and sensitivity for cultural differences, minimizes disruptions to the target environment and provides a framework to ensure changes were successfully implemented.</li>
            <li>Ability to successfully manage relationships between various divisions, vendors, and resources to fully meet business process and change management needs. To include training, ad hoc reporting, defining policies, methods and procedures and best practices. Provide post implementation results as assigned.</li>
            <li>Skills shall include business processes expertise, analytical thinking, business acumen, excellent written and verbal communication abilities.</li>
          </ul>
        </div>

        <div className="section">
          <h3>You are expected to deliver, but not limited to, the following:</h3>
          <ul>
            <li>Create best practices documentation and provide training to users.</li>
                <ul>
                    <li>Facilitate table-top exercises and drop-in calls to communicate process improvements and provide training.</li>
                    <li>Create Quick Reference Guides and other graphic training materials.</li>
                </ul>
            <li>Support the implementation and/or improvement of ITIL best practices at the operational level for incident, problem, change, release, and configuration management utilizing ServiceNow platform.</li>
                <ul>
                    <li>Specifically, enhance Change Management process to implement ITIL and Agile best practice interactions.</li>
                    <li>Enhance Request Fulfilment process and update the Service Catalog.</li>
                    <li>Support the development of Process KPIs to measure effectiveness and efficiency of processes.</li>
                    <li>Recommend and support the development of dashboards for service management.</li>
                </ul>
            <li>Participate on Agile Scrum Team and manage work using Agile practices.</li>
                <ul>
                    <li>Manage work (deliverables) utilizing Kanban methodology/boards.</li>
                </ul>
            <li>Support other IT Business Management team work as needed.</li>
          </ul>
        </div>

        <div className="section">
          <h2>PLACE OF PERFORMANCE:</h2>
          <ul>
            <li>On-site at Client locations, Washington, DC.</li>
            <li>NOTE: Work can and will be conducted primarily offsite; however, the role will require occasional physical presence on-site at Client locations in Washington, DC (estimate 4 days per month for various administrative actions and delivery team meetings.)</li>
          </ul>
        </div>

        <div className="section">
          <h2>CITIZEN STATUS:</h2>
          <ul>
            <li>NOTE: U.S. Citizenship required.</li>
          </ul>
        </div>
      </div>
      <h1>
        Qualified? Email{' '}
        <a href="mailto:hr@bxtpartners.com">hr@bxtpartners.com</a>.
      </h1>
    </div>
  );
}

export default BusinessProcessEngineer;
