import React, { useEffect } from 'react';
import '../styles/Position.css'; // Make sure to import your CSS file

function AppDev() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="jobDescriptionPage">
      <h1>Application Developer</h1>

      <div className="jobDescription">
        <p>
          We are looking for a motivated Full Stack developer to join the team to support Operations, maintenance, and enhancement of the Integrated Research and Analytics Platform (IRAP) Portal, which is a custom-built web application built using .Net and React on AWS GovCloud infrastructure. This platform provides seamless data management and analytics experience that allows researchers the ability to search data catalogs, access data, and collaborate with others.
        </p>

        <p>
        Note: This is a part-time position, and we would only need the candidate 50% of the time/~20 hours per week.
        </p>

        <div className="section">
          <h2>Qualifications</h2>
          <ul>
            <li>Bachelor's degree in computer science or a related degree in the IT field or equivalent experience.</li>
            <li>At least five (5) years programmer / analyst experience in full cycle of software development.</li>
            <li>5 years' experience designing, building, and maintaining custom web applications using .Net, react, HTML, and CSS.</li>
            <li>5 years' experience in designing and building secure web applications and microservices.</li>
            <li>3 years' experience in SQL.</li>
            <li>3 years' experience in presenting and defending Application Architecture to Architecture Review Boards or similar regulatory bodies.</li>
            <li>5 years' experience using Agile software development methodologies.</li>
            <li>5 years' experience building CI/CD pipelines, preferably using Azure DevOps or Gitlab.</li>
            <li>Excellent communication and interpersonal skills and ability to effectively interact with stakeholders of varying levels of technology acumen.</li>
            <li>Knowledge of FISMA security review and ATO process.</li>
            <li>Experience acquiring in-depth understanding of large complex software systems to isolate defects, reproduce defects, assess risk, and understand varied customer deployments.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Capabilities</h2>
          <ul>
            <li>Analyzes functional business applications and design specifications for functional activities.</li>
            <li>Develops block diagrams and logic flow charts.</li>
            <li>Translates detailed design into computer software.</li>
            <li>Tests, debugs and refines the computer software to produce the required product.</li>
            <li>Prepares required documentation, including both program-level and user-level documentation.</li>
            <li>Enhances software to reduce operating time or improve efficiency.</li>
            <li>Provides technical direction to programmers to ensure program deadlines are met.</li>
            <li>The ability to develop, upgrade, implement, test, and document software components.</li>
            <li>The ability to develop solutions with team members to implement previously defined and documented requirements.</li>
            <li>The knowledge to use Oracle Forms and Reports for custom development.</li>
            <li>The knowledge to create custom interfaces and APIs.</li>
            <li>The ability to write Transact-SQL, PL/SQL and stored procedures, packages, and triggers.</li>
            <li>The capability to customize Workflows.</li>
            <li>The ability to participate with the teams in the software development life cycle:
              <ul>
                <li>Requirements analysis</li>
                <li>System design</li>
                <li>Development</li>
                <li>Unit testing</li>
                <li>System integration</li>
                <li>Production support</li>
              </ul>
            </li>
            <li>Effective analytical abilities.</li>
            <li>Oral and written communication skills.</li>
            <li>Work effectively as a team member.</li>
            <li>Experience supporting users.</li>
            <li>The ability to direct, review, and approve the design and technical specification of well-documented, efficient and effective solutions to meet business needs and provide responsive support to production issues.</li>
            <li>The knowledge to use Application Engine Programs, SQR, PeopleCode, and XML/BI Publisher for custom development.</li>
            <li>The knowledge to create custom Component Interfaces, interfaces, integration tools, and APIs.</li>
            <li>The knowledge to write SQL, Powershell, and UNIX Shell Scripts.</li>
            <li>The ability to use application packages.</li>
            <li>The ability to program in Microsoft .Net environment - VB.net and ASP.net.</li>
            <li>The ability to customize Workflows.</li>
            <li>The knowledge to use PeopleCode Debugger to analyze component buffers and troubleshoot problems.</li>
            <li>The ability to apply tax updates and maintenance packs using Change Assistant.</li>
          </ul>
        </div>

        <div className="section">
          <h2>You shall deliver, but not limited to, the following:</h2>
          <ul>
            <li>Maintain the IRAP Portal webapp built using React, CSS, JS, HTML, Dynamo DB and .Net by performing necessary software/framework version upgrades, patches etc.</li>
            <li>Make enhancements to IRAP Portal front end, back end, and associated infrastructure in AWS per user needs.</li>
            <li>Update the design of IRAP Portal webapp to meet customer needs as well as scalability, resiliency, and cost optimization requirements.</li>
            <li>Update and maintain architecture artifacts and system documentation of IRAP.</li>
            <li>Represent IRAP team in Architecture Review Board review sessions.</li>
            <li>Enhance the logging, monitoring, and alerting capabilities of IRAP Portal.</li>
            <li>Ensure continuous compliance with FISMA standards to maintain ATO for IRAP.</li>
            <li>Create and implement NIST 800-53 internal controls for maintaining/renewing ATO for IRAP as well as to increase the sensitivity level of IRAP.</li>
            <li>Build, enhance, and manage devops pipeline for IRAP.</li>
            <li>Build data infrastructure in AWS using Terraform IaC.</li>
            <li>Recommend areas of improvements and enhancements for IRAP.</li>
          </ul>
        </div>

        <div className="section">
          <h2>Place of Performance</h2>
          <p>Remote - Eastern Standard Time</p>
        </div>

        <div className="section">
          <h2>Citizenship Status</h2>
          <p>US citizen is required. Green card holders accepted.</p>
        </div>
      </div>
      <h1>
        Qualified? Email{' '}
        <a href="mailto:hr@bxtpartners.com">hr@bxtpartners.com.</a>
      </h1>
    </div>
  );
}

export default AppDev;
